<template>
  <v-responsive style="margin-top: 1% !important; max-width: 250px">
    <div class="d-flex flex-row align-center">
      <v-text-field
        id="findtext-menu"
        v-model="searchText"
        clearable
        flat
        hide-details
        label="Search..."
        name="find"
        background-color="#F2F2F2"
        placeholder="Search..."
        single-line
        rounded
        @keydown.enter="enterSearch"
        @click:clear="clearTextSearch"
        @click:prepend-inner="enterSearch"
      >
      </v-text-field>

      <v-btn
        icon
        fab
        dark
        style="background-color: #85cbe9; margin-left: -15px"
      >
        <v-icon color="white">mdi-magnify</v-icon>
      </v-btn>
    </div>
  </v-responsive>
</template>

<script>
import searchService from "@/providers/SearchService";
import { mapMutations } from "vuex";

export default {
  name: "SearchBoxComponent",
  data: () => ({
    searchText: null,
  }),
  methods: {
    toTop() {
      this.$vuetify.goTo(0);
    },
    invokeSearch(value) {
      searchService.search(value).then((record) => {
        this.SetResultSearch(record.result);
      });
    },
    enterSearch(event) {
      console.log(event);
      this.SetSearchText(this.searchText);
      this.$store.commit("ShowSearchPanel");
      this.$store.dispatch("SearchText");
      this.searchText = null;
      this.toTop();
      /*.then(response => {
                    console.log('response', response);
                    console.log('store.state.resultSearch', store.getters.getResultSearch);
                });*/
    },
    clearTextSearch() {
      this.searchText = null;
      this.$store.commit("HideSearchPanel");
      this.$store.commit("ClearSearchText");
      this.$store.commit("ClearResultSearch");
    },
    ...mapMutations(["SetResultSearch", "SetSearchText"]),
  },
};
</script>

<style scoped></style>
