<template>
  <v-navigation-drawer
    app
    clipped
    disable-resize-watcher
    v-model="drawer"
    v-bind="$attrs"
    temporary
  >
    <template v-slot:prepend>
      <v-list-item link to="/homepage">
        <v-list-item-content>
          <v-img
            contain
            width="50"
            height="50"
            class="shrink"
            src="@/assets/images/logo-bhc.png"
          ></v-img>
        </v-list-item-content>
        <!--<v-list-item-content>
                    <v-list-item-title>Turismo BC</v-list-item-title>
                </v-list-item-content>-->
      </v-list-item>
    </template>

    <v-divider></v-divider>

    <v-list dense>
      <v-list-item link to="/hospitals-clinics">
        <v-list-item-icon>
          <v-icon>mdi-hospital-building</v-icon>
        </v-list-item-icon>

        <v-list-item-title>Hospitales y Clínicas</v-list-item-title>
      </v-list-item>

      <v-list-item link to="/medical-services">
        <v-list-item-icon>
          <v-icon>mdi-doctor</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Servicios Médicos</v-list-item-title>
      </v-list-item>

      <v-list-group
        v-for="(item, index) in items"
        :key="index"
        :prepend-icon="item.icon"
        v-model="item.active"
      >
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title v-text="item.title"></v-list-item-title>
          </v-list-item-content>
        </template>

        <v-list-item
          link
          v-for="(child, idx) in item.children"
          :key="idx"
          :to="child.link"
        >
          <v-list-item-icon class="sicon">
            <v-icon color="primary">{{ child.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title v-text="child.title"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>

      <v-list-item link to="/blogs">
        <v-list-item-icon>
          <v-icon>mdi-blogger</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Blogs</v-list-item-title>
      </v-list-item>
    </v-list>
    <template v-slot:append>
      <div class="pa-2">
        <v-btn block depressed color="primary" to="/authentication">
          <v-icon left>mdi-account-circle</v-icon>
          <span>Administrar</span>
        </v-btn>
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
import { menuItemsVertical } from "@/layouts/app-layout/menu/MenuItems.js";

export default {
  name: "VerticalMenu",
  props: {
    show: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    drawer: false,
    group: null,
    items: menuItemsVertical,
  }),
  watch: {
    show() {
      this.drawer = !this.drawer;
    },
  },
};
</script>

<style scoped>
.v-btn {
  text-transform: none !important;
}
</style>
