<template>
  <div>
    <v-app-bar app color="white" height="85" light elevation="3">
      <v-app-bar-nav-icon
        class="hidden-md-and-up"
        @click.stop="drawer = !drawer"
      ></v-app-bar-nav-icon>
      <horizontal-menu
        v-if="$vuetify.breakpoint.mdAndUp"
        :search-text="valueToSearch"
      />
      <v-spacer v-if="$vuetify.breakpoint.smAndDown"></v-spacer>
      <search-box-component v-if="$vuetify.breakpoint.smAndDown" class="mr-2" />

      <cart v-if="$vuetify.breakpoint.smAndDown" />
    </v-app-bar>
    <vertical-menu :show="drawer" v-if="$vuetify.breakpoint.mdAndDown" />
  </div>
</template>

<script>
import HorizontalMenu from "@/layouts/app-layout/menu/HorizontalMenu";
import VerticalMenu from "@/layouts/app-layout/menu/VerticalMenu";
import SearchBoxComponent from "@/components/search-box/SearchBoxComponent";
import Cart from "../../../components/cart/Cart.vue";

export default {
  name: "MenuComponent",
  components: { SearchBoxComponent, VerticalMenu, HorizontalMenu, Cart },
  data: () => ({
    drawer: false,
    valueToSearch: null,
    results: [],
  }),
};
</script>

<style scoped>
.v-btn {
  text-transform: none !important;
}
</style>
