var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-menu',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],staticClass:"hidden-md-and-up",attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"background-color":"#f2f2f2"},attrs:{"icon":"","fab":"","dark":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"#292D32","dark":""}},[_vm._v(" mdi-cart ")]),(_vm.cart.length > 0)?_c('span',{staticClass:"cart-badge",staticStyle:{"color":"#292d32"}},[_vm._v(_vm._s(_vm.cart.length))]):_vm._e()],1)]}}])},[_c('v-list',{attrs:{"dense":""}},[_vm._l((_vm.cart),function(product,index){return _c('v-list-item',{key:index,attrs:{"router":"","to":_vm.generateDynamicRoute(product)}},[_c('v-list-item-avatar',[_c('v-img',{attrs:{"src":product.image}})],1),_c('v-list-item-content',{staticStyle:{"max-width":"400px"}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(`${product.category} - ${product.name}`)+" ")]),_c('small',[_c('b',[_vm._v(" Teléfono: ")]),_vm._v(" "+_vm._s(_vm._f("formatPhone")(product.profile.phone ? product.profile.phone : product.profile.phone1,"MX")))]),_c('small',[_c('b',[_vm._v(" Horarios: ")]),_vm._v(" "+_vm._s(product.profile.servicehours))])],1),_c('v-list-item-icon',[_c('v-btn',{style:({
              marginTop: _vm.$vuetify.breakpoint.smAndDown ? '25%' : '10%',
            }),on:{"click":function($event){return _vm.removeItem(index)}}},[_c('v-icon',[_vm._v(" mdi-delete ")])],1)],1)],1)}),_c('v-list-item',[(_vm.cart.length)?_c('v-btn',{attrs:{"block":"","color":"success","dark":""},on:{"click":_vm.openReservationForm}},[_vm._v(" Hacer reservación ")]):_c('v-list-item-title',[_vm._v(" El carrito esta vació ")])],1)],2)],1),_c('v-dialog',{attrs:{"max-width":"600"},model:{value:(_vm.reservationFormDialog),callback:function ($$v) {_vm.reservationFormDialog=$$v},expression:"reservationFormDialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Formulario de Reservación")])]),_c('v-card-text',[_c('v-form',{ref:"reservationFormRef"},[_c('v-text-field',{attrs:{"label":"Nombres","rules":[
              (v) => !!v || 'Nombre es requerido',
              (v) =>
                /^([a-zA-Z]+\s)*[a-zA-Z]+$/.test(v) ||
                'Solo letras y espacios permitidos',
            ]},model:{value:(_vm.reservationForm.name),callback:function ($$v) {_vm.$set(_vm.reservationForm, "name", $$v)},expression:"reservationForm.name"}}),_c('v-text-field',{attrs:{"label":"Apellidos","rules":[
              (v) => !!v || 'Apellidos son requeridos',
              (v) =>
                /^([a-zA-Z]+\s)*[a-zA-Z]+$/.test(v) ||
                'Solo letras y espacios permitidos',
            ]},model:{value:(_vm.reservationForm.surnames),callback:function ($$v) {_vm.$set(_vm.reservationForm, "surnames", $$v)},expression:"reservationForm.surnames"}}),_c('v-text-field',{attrs:{"label":"Teléfono","rules":[(v) => /^[0-9]+$/.test(v) || 'Solo números permitidos']},model:{value:(_vm.reservationForm.phone),callback:function ($$v) {_vm.$set(_vm.reservationForm, "phone", $$v)},expression:"reservationForm.phone"}}),_c('v-text-field',{attrs:{"label":"Correo Electrónico","rules":[
              (v) => !!v || 'Correo Electrónico es requerido',
              (v) => /.+@.+\..+/.test(v) || 'Formato de correo inválido',
            ]},model:{value:(_vm.reservationForm.email),callback:function ($$v) {_vm.$set(_vm.reservationForm, "email", $$v)},expression:"reservationForm.email"}})],1)],1),_c('v-card-actions',[_c('v-btn',{on:{"click":_vm.closeReservationForm}},[_vm._v("Cancelar")]),_c('v-btn',{attrs:{"color":"success"},on:{"click":_vm.submitReservationForm}},[_vm._v("Enviar Reservación")])],1)],1)],1),_c('v-snackbar',{attrs:{"timeout":2000},scopedSlots:_vm._u([{key:"action",fn:function({ attrs }){return [_c('v-btn',_vm._b({attrs:{"icon":""},on:{"click":function($event){_vm.snackBar = false}}},'v-btn',attrs,false),[_c('v-icon',[_vm._v("mdi-close")])],1)]}}]),model:{value:(_vm.snackBar),callback:function ($$v) {_vm.snackBar=$$v},expression:"snackBar"}},[_vm._v(" "+_vm._s(_vm.snackText)+" ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }