<template>
  <div>
    <v-menu class="hidden-md-and-up" offset-y v-show="false">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          icon
          fab
          dark
          style="background-color: #f2f2f2"
        >
          <v-icon color="#292D32" dark> mdi-cart </v-icon>

          <span
            v-if="cart.length > 0"
            class="cart-badge"
            style="color: #292d32"
            >{{ cart.length }}</span
          >
        </v-btn>
      </template>

      <v-list dense>
        <v-list-item
          :key="index"
          router
          v-for="(product, index) in cart"
          :to="generateDynamicRoute(product)"
        >
          <v-list-item-avatar>
            <v-img :src="product.image"></v-img>
          </v-list-item-avatar>

          <v-list-item-content style="max-width: 400px">
            <v-list-item-title>
              {{ `${product.category} - ${product.name}` }}
            </v-list-item-title>
            <small>
              <b> Teléfono: </b>
              {{
                product.profile.phone
                  ? product.profile.phone
                  : product.profile.phone1 | formatPhone("MX")
              }}</small
            >

            <small>
              <b> Horarios: </b>
              {{ product.profile.servicehours }}</small
            >
          </v-list-item-content>

          <v-list-item-icon>
            <v-btn
              @click="removeItem(index)"
              :style="{
                marginTop: $vuetify.breakpoint.smAndDown ? '25%' : '10%',
              }"
            >
              <v-icon> mdi-delete </v-icon>
            </v-btn>
          </v-list-item-icon>
        </v-list-item>

        <v-list-item>
          <v-btn
            block
            color="success"
            dark
            @click="openReservationForm"
            v-if="cart.length"
          >
            Hacer reservación
          </v-btn>

          <v-list-item-title v-else> El carrito esta vació </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <v-dialog v-model="reservationFormDialog" max-width="600">
      <v-card>
        <v-card-title>
          <span class="headline">Formulario de Reservación</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="reservationFormRef">
            <v-text-field
              v-model="reservationForm.name"
              label="Nombres"
              :rules="[
                (v) => !!v || 'Nombre es requerido',
                (v) =>
                  /^([a-zA-Z]+\s)*[a-zA-Z]+$/.test(v) ||
                  'Solo letras y espacios permitidos',
              ]"
            ></v-text-field>
            <v-text-field
              v-model="reservationForm.surnames"
              label="Apellidos"
              :rules="[
                (v) => !!v || 'Apellidos son requeridos',
                (v) =>
                  /^([a-zA-Z]+\s)*[a-zA-Z]+$/.test(v) ||
                  'Solo letras y espacios permitidos',
              ]"
            ></v-text-field>
            <v-text-field
              v-model="reservationForm.phone"
              label="Teléfono"
              :rules="[(v) => /^[0-9]+$/.test(v) || 'Solo números permitidos']"
            ></v-text-field>
            <v-text-field
              v-model="reservationForm.email"
              label="Correo Electrónico"
              :rules="[
                (v) => !!v || 'Correo Electrónico es requerido',
                (v) => /.+@.+\..+/.test(v) || 'Formato de correo inválido',
              ]"
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="closeReservationForm">Cancelar</v-btn>
          <v-btn color="success" @click="submitReservationForm"
            >Enviar Reservación</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackBar" :timeout="2000">
      {{ snackText }}
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" icon @click="snackBar = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import CartService from "@/providers/CartService";

export default {
  name: "Cart",

  data: () => ({
    cart: [],

    reservationFormDialog: false,
    reservationForm: {
      name: "",
      surnames: "",
      phone: "",
      email: "",
      relations: {},
    },

    snackBar: false,
    snackText: "",
  }),

  filters: {
    formatPhone(phn, country) {
      // console.log('phn:', phn);
      if (phn == "undefined" || phn == null || phn === "") {
        return country;
      }
      switch (country) {
        case "US":
          return (
            "USA +1 (" +
            phn.substring(0, 3) +
            ") " +
            phn.substring(3, 6) +
            "-" +
            phn.substring(6)
          );
        case "MX":
          return (
            "MEX +52 (" +
            phn.substring(0, 3) +
            ") " +
            phn.substring(3, 6) +
            "-" +
            phn.substring(6)
          );
        default:
          return country;
      }
    },
  },

  methods: {
    openReservationForm() {
      this.reservationFormDialog = true;
    },

    closeReservationForm() {
      this.reservationFormDialog = false;
    },

    pluralizeCategory(category) {
      const pluralMap = {
        Hospital: "hospitales",
        Clínica: "clinics",
        Doctor: "doctors",
        Restaurant: "restaurants",
        Ocio: "leisures",
        Hotel: "hotels",
        Farmacia: "pharmaceutics",
        Laboratorio: "laboratories",
        Transporte: "transportations",
        Viñedo: "vineyards",
        Casa: "houses",
      };

      return pluralMap[category] || category.toLowerCase() + "s";
    },

    submitReservationForm() {
      if (this.$refs.reservationFormRef.validate()) {
        const relations = {};

        this.cart.forEach((product) => {
          const category = this.pluralizeCategory(product.category);
          const profileId = product.profile.id;

          if (!relations[category]) {
            relations[category] = [];
          }

          relations[category].push(profileId);
        });

        this.reservationForm.relations = relations;

        // Lógica para enviar el formulario si pasa la validación

        CartService.addCart(this.reservationForm);

        // console.log("Formulario enviado:", this.reservationForm);

        // limpiar cart

        this.$store.dispatch("clearCart");
        this.cart = this.$store.getters.getCart;

        // Cierra el diálogo después de enviar el formulario
        this.closeReservationForm();

        this.snackText =
          "La reservación se ha enviado con éxito al correo proporcionado";

        this.snackBar = true;
      }
    },

    generateDynamicRoute(product) {
      const categoryMap = {
        Hospital: "hospital-profile",
        Clínica: "clinic-profile",
        Doctor: "doctor-profile",
        Restaurant: "restaurant-profile",
        Ocio: "leisure-profile",
        Hotel: "hotel-profile",
        Farmacia: "pharmaceutic-profile",
        Laboratorio: "laboratory-profile",
        Transporte: "transportation-profile",
        Viñedo: "vineyard-profile",
        Casa: "house-profile",
      };

      const category = product.category;
      const profileId = product.profile.id;

      if (category in categoryMap) {
        return `/${categoryMap[category]}/${profileId}`;
      }
    },

    removeItem(index) {
      this.$store.dispatch("removeFromCart", index);
    },
  },

  mounted() {
    this.cart = this.$store.getters.getCart;
    console.log(this.cart);
  },
};
</script>
